//
// Component: Alert
//

.alert {
  .icon {
    margin-right: 10px;
  }

  .btn-close {
    color: $black;
    opacity: .2;

    &:hover {
      opacity: .5;
    }
  }

  a {
    color: $white;
    text-decoration: underline;
  }
}

//Alert Variants
@each $color, $value in $theme-colors {
  .alert-#{$color} {
    color: color-contrast($value);
    background-color: $value;
    border-color: shade-color($value, 5%);
  }

  $alert-background: shift-color($value, $alert-bg-scale);
  $alert-border: shift-color($value, $alert-border-scale);
  $alert-color: shift-color($value, $alert-color-scale);
  @if (contrast-ratio($alert-background, $alert-color) < $min-contrast-ratio) {
    $alert-color: mix($value, color-contrast($alert-background), abs($alert-color-scale));
  }
  .alert-#{$color} {
    @include alert-variant($alert-background, $alert-border, $alert-color);
  }
}

@include dark-mode () {
  @each $color, $value in $theme-colors-alt {
    .alert-#{$color} {
      color: color-contrast($value);
      background-color: $value;
      border-color: shade-color($value, 5%);
    }

    $alert-background: shift-color($value, $alert-bg-scale);
    $alert-border: shift-color($value, $alert-border-scale);
    $alert-color: shift-color($value, $alert-color-scale);
    @if (contrast-ratio($alert-background, $alert-color) < $min-contrast-ratio) {
      $alert-color: mix($value, color-contrast($alert-background), abs($alert-color-scale));
    }
    .alert-#{$color} {
      @include alert-variant($alert-background, $alert-border, $alert-color);
    }
  }
}

//
