//
// Mixins: Custom Forms
//

// Custom Range Variant
@mixin form-range-variant($name, $color) {
  &.form-range-#{$name} {
    &:focus {
      outline: none;

      &::-webkit-slider-thumb {
        box-shadow: 0 0 0 1px $body-bg, 0 0 0 2px rgba($color, .25);
      }

      &::-moz-range-thumb     {
        box-shadow: 0 0 0 1px $body-bg, 0 0 0 2px rgba($color, .25);
      }

      &::-ms-thumb            {
        box-shadow: 0 0 0 1px $body-bg, 0 0 0 2px rgba($color, .25);
      }
    }

    &::-webkit-slider-thumb {
      background-color: $color;

      &:active {
        background-color: tint-color($color, 35%);
      }
    }

    &::-moz-range-thumb {
      background-color: $color;

      &:active {
        background-color: tint-color($color, 35%);
      }
    }

    &::-ms-thumb {
      background-color: $color;

      &:active {
        background-color: tint-color($color, 35%);
      }
    }
  }
}
