//
// Component: Forms
//

.form-group {
  &.has-icon {
    position: relative;

    .form-control {
      padding-right: 35px;
    }

    .form-icon {
      background-color: transparent;
      border: 0;
      cursor: pointer;
      font-size: 1rem;
      // margin-top: -3px;
      padding: $input-btn-padding-y $input-btn-padding-x;
      position: absolute;
      right: 3px;
      top: 0;
    }
  }
}

// Button groups
.btn-group-vertical {
  .btn {
    &.btn-flat:first-of-type,
    &.btn-flat:last-of-type {
      @include border-radius(0);
    }
  }
}

// Support icons in form-control
.form-control-feedback {
  &.fa,
  &.fas,
  &.far,
  &.fab,
  &.fal,
  &.fad,
  &.svg-inline--fa,
  &.ion {
    line-height: $input-height;
  }
}

.input-lg + .form-control-feedback,
.input-group-lg + .form-control-feedback {
  &.fa,
  &.fas,
  &.far,
  &.fab,
  &.fal,
  &.fad,
  &.svg-inline--fa,
  &.ion {
    line-height: $input-height-lg;
  }
}

.form-group-lg {
  .form-control + .form-control-feedback {
    &.fa,
    &.fas,
    &.far,
    &.fab,
    &.fal,
    &.fad,
    &.svg-inline--fa,
    &.ion {
      line-height: $input-height-lg;
    }
  }
}

.input-sm + .form-control-feedback,
.input-group-sm + .form-control-feedback {
  &.fa,
  &.fas,
  &.far,
  &.fab,
  &.fal,
  &.fad,
  &.svg-inline--fa,
  &.ion {
    line-height: $input-height-sm;
  }
}

.form-group-sm {
  .form-control + .form-control-feedback {
    &.fa,
    &.fas,
    &.far,
    &.fab,
    &.fal,
    &.fad,
    &.svg-inline--fa,
    &.ion {
      line-height: $input-height-sm;
    }
  }
}

label:not(.form-check-label) {
  font-weight: $font-weight-bold;
}

.warning-feedback {
  @include font-size($form-feedback-font-size);
  color: $warning;
  display: none;
  margin-top: $form-feedback-margin-top;
  width: 100%;
}

.warning-tooltip {
  @include border-radius($form-feedback-tooltip-border-radius);
  @include font-size($form-feedback-tooltip-font-size);
  background-color: rgba($warning, $form-feedback-tooltip-opacity);
  color: color-contrast($warning);
  display: none;
  line-height: $form-feedback-tooltip-line-height;
  margin-top: .1rem;
  max-width: 100%; // Contain to parent when possible
  padding: $form-feedback-tooltip-padding-y $form-feedback-tooltip-padding-x;
  position: absolute;
  top: 100%;
  z-index: 5;
}

.form-control {
  &.is-warning {
    border-color: $warning;

    @if $enable-validation-icons {
      // padding-right: $input-height-inner;
      // background-image: none;
      // background-repeat: no-repeat;
      // background-position: center right $input-height-inner-quarter;
      // background-size: $input-height-inner-half $input-height-inner-half;
    }

    &:focus {
      border-color: $warning;
      box-shadow: 0 0 0 $input-focus-width rgba($warning, .25);
    }

    ~ .warning-feedback,
    ~ .warning-tooltip {
      display: block;
    }
  }
}

// stylelint-disable-next-line selector-no-qualifying-type
textarea.form-control {
  &.is-warning {
    @if $enable-validation-icons {
      padding-right: $input-height-inner;
      background-position: top $input-height-inner-quarter right $input-height-inner-quarter;
    }
  }
}

.form-select {
  &.is-warning {
    border-color: $warning;

    @if $enable-validation-icons {
      // padding-right: $form-select-feedback-icon-padding-right;
      // background: $form-select-background, none $form-select-bg no-repeat $form-select-feedback-icon-position / $form-select-feedback-icon-size;
    }

    &:focus {
      border-color: $warning;
      box-shadow: 0 0 0 $input-focus-width rgba($warning, .25);
    }

    ~ .warning-feedback,
    ~ .warning-tooltip {
      display: block;
    }
  }
}

.form-check-input {
  &.is-warning {
    ~ .form-check-label {
      color: $warning;
    }

    ~ .warning-feedback,
    ~ .warning-tooltip {
      display: block;
    }
  }
}

// body.text-sm support
body.text-sm {
  .input-group-text {
    font-size: $font-size-sm;
  }
}

// custom .form-control styles
.form-control,
.form-select {
  &.form-control-border {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    box-shadow: inherit;

    &.border-width-2 {
      border-bottom-width: 2px;
    }
    &.border-width-3 {
      border-bottom-width: 3px;
    }
  }
}

// custom range color variations
.form-range {
  @each $name, $color in $theme-colors {
    @include form-range-variant($name, $color);
  }

  @each $name, $color in $colors {
    @include form-range-variant($name, $color);
  }
}

.navbar-dark {
  .btn-navbar,
  .form-control-navbar {
    background-color: tint-color($sidebar-dark-bg, 5%);
    border: 1px solid tint-color($sidebar-dark-bg, 15%);
    color: tint-color(color-contrast(tint-color($sidebar-dark-bg, 5%)), 15%);
  }
  .btn-navbar {
    &:hover {
      background-color: tint-color($sidebar-dark-bg, 7.5%);
    }
    &:focus {
      background-color: tint-color($sidebar-dark-bg, 10%);
    }
  }
}

@include dark-mode () {
  .form-control:not(.form-control-navbar):not(.form-control-sidebar),
  .form-select,
  .custom-control-label::before,
  .input-group-text {
    background-color: $dark;
    color: $white;
  }
  .form-control:not(.form-control-navbar):not(.form-control-sidebar):not(.is-invalid):not(:focus) {
    border-color: $gray-600;
  }
  select {
    background-color: $dark;
    color: $white;
    border-color: $gray-600;
  }

  .form-select {
    background: $dark $form-select-dark-background;

    &[multiple]{
      background: $dark;
    }
  }

  .input-group-text {
    border-color: $gray-600;
  }

  .form-range {
    &::-webkit-slider-runnable-track {
      background-color: tint-color($dark, 7.5%);
    }
    &::-moz-range-track {
      background-color: tint-color($dark, 7.5%);
    }
    &::-ms-track {
      background-color: tint-color($dark, 7.5%);
    }

    @each $name, $color in $theme-colors-alt {
      @include form-range-variant($name, $color);
    }

    @each $name, $color in $colors-alt {
      @include form-range-variant($name, $color);
    }
  }

}
